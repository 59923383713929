<template>
  <div class="mt-5">
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-primary con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Tambahkan data untuk mengurangi nilai SPP jika ada pekerjaan tidak terselesaikan.
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center space-x-3">
      <vs-button color="primary" size="small" icon-pack="feather" icon="icon-plus" class="px-3 mt-3" @click="modalAdd.active = true">Tambah</vs-button>
      <vs-button color="primary" size="small" type="border" icon-pack="feather" icon="icon-refresh-cw" class="px-3 mt-3" @click="getData">Refresh</vs-button>
    </div>
    <vs-progress class="" indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>
    <div class="overflow-auto">
      <vs-table :data="items" stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">Action</vs-th>
          <vs-th class="whitespace-no-wrap">Nomor</vs-th>
          <vs-th class="whitespace-no-wrap">No Jurnal</vs-th>
          <vs-th class="whitespace-no-wrap">Termin</vs-th>
          <vs-th class="whitespace-no-wrap">DPP</vs-th>
          <vs-th class="whitespace-no-wrap">PPN</vs-th>
          <vs-th class="whitespace-no-wrap">Total Nilai</vs-th>
          <vs-th class="whitespace-no-wrap">PPh</vs-th>
          <vs-th class="whitespace-no-wrap">Nominal ke Rekanan</vs-th>
          <vs-th class="whitespace-no-wrap">Pekerjaan</vs-th>
          <vs-th class="whitespace-no-wrap">Lihat Files</vs-th>
          <vs-th class="whitespace-no-wrap">Status Approval</vs-th>
          <vs-th class="whitespace-no-wrap">Progress Approval</vs-th>
          <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          <vs-th class="whitespace-no-wrap">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center space-x-2">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button class="py-2 px-3" type="filled" size="small" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item class="p-1" :disabled="item.status_approval !== 'DITERIMA'" @click="print(item.id)">Print Addendum</vs-dropdown-item>
                    <vs-dropdown-item class="p-1" :disabled="!!item.id_jurnal" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.nomor }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_jurnal || '-' }}</vs-td>
            <vs-td class="whitespace-no-wrap">Termin {{ item.no_termin }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.total_dpp | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.total_jml_nilai_ppn | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.total_jml_nilai | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.total_jml_nilai_pph | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.total_jml_nilai_pokok | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.pekerjaan_count }} Pekerjaan</vs-td>
            <vs-td class="whitespace-no-wrap text-right">
              <vs-button color="warning" v-if="item.files_url.length > 0" type="filled" size="small" icon-pack="feather" icon="icon-file" class="px-2" @click="showFiles(item)">Lihat Files</vs-button>
              <div v-else class="text-sm">-</div>
            </vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.status_approval">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_approval_color">
                  <span class="text-xs">{{ item.status_approval }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap"><span class="underline cursor-pointer" @click="showModalApproval(item)">Lihat Progress</span></vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.created_at }}</vs-td>
          </vs-tr>
          <!--footer-->
          <vs-tr v-if="items.length > 0" class="text-sm font-bold">
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td class="text-right">{{ footer.grandTotalDpp | idr }}</vs-td>
            <vs-td class="text-right">{{ footer.grandTotalPpn | idr }}</vs-td>
            <vs-td class="text-right">{{ footer.grandTotalNilai | idr }}</vs-td>
            <vs-td class="text-right">{{ footer.grandTotalPph | idr }}</vs-td>
            <vs-td class="text-right">{{ footer.grandTotalNominal | idr }}</vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <!--modals-->
    <SpkAdjustAdd :isActive.sync="modalAdd.active" @success="getData"/>
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>

  </div>
</template>

<script>
import _ from 'lodash'
import SpkAdjustRepository from '@/repositories/proyek/spk-adjust-repository'
import ModalApprovalProgress from '@/views/components/approval-progress/ModalApprovalProgress'
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import printoutMixin from '@/utilities/mixins/printout-mixin'

export default {
  name: 'TabAdjust',
  props: {
    isActive: { type: Boolean }
  },
  mixins: [printoutMixin],
  components: {
    SpkAdjustAdd: () => import('@/views/pages/proyek/spk/tabs-show/adjust/SpkAdjustAdd'),
    ModalApprovalProgress,
    FilesViewer
  },
  computed: {
    footer () {
      return {
        grandTotalDpp: _.sumBy(this.items, item => parseFloat(item.total_dpp)),
        grandTotalPpn: _.sumBy(this.items, item => parseFloat(item.total_jml_nilai_ppn)),
        grandTotalNilai: _.sumBy(this.items, item => parseFloat(item.total_jml_nilai)),
        grandTotalPph: _.sumBy(this.items, item => parseFloat(item.total_jml_nilai_pph)),
        grandTotalNominal: _.sumBy(this.items, item => parseFloat(item.total_jml_nilai_pokok))
      }
    }
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.isFirstActive = false
        this.getData()
      }
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      modalAdd: {
        active: false
      },
      items: [],
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      },
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    getData () {
      this.isLoading = true

      const params = {
        id_spk: this.$route.params.idSpk
      }
      SpkAdjustRepository.get(params)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showModalApproval (item) {
      this.modalApproval.type = 'SPP-ADJUST'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    delete (id) {
      SpkAdjustRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    print (id) {
      this.showPrintOutFile('SPP_ADJUST', { id_spk_adjust: id })
    },

    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    }
  }
}
</script>
